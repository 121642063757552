"use client";

import { FullPageError } from "@carbon/ibm-products";
import Link from "next/link";
import React from "react";

export default function NotFound(): JSX.Element {
  return (
    <FullPageError
      description="We couldn't find the page you're looking for. This could be because the page has moved, was deleted, or the URL was typed incorrectly. Please check the URL and try again, or use the link below to return to our homepage."
      kind="404"
      label="Error 404"
      title="Page Not Found"
    >
      <Link href="/">Return to Home Page</Link>
    </FullPageError>
  );
}
